body {
  font-family: "Inter Tight", "sans-serif";
}

*::-webkit-scrollbar {
  width: 6px;
}

*::-webkit-scrollbar-track {
  background: #eeeeee;
  border-radius: 10px;
}

*::-webkit-scrollbar-thumb {
  background-color: #bcbcbc;
  border-radius: 10px;
}

.activemenu {
  color: #000;
}
